@import url("https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@300&display=swap");

.pg-user-prof-div-content {
  display: flex;
  flex-direction: column;
  width: 80%;
  text-align: left;
  background: #000000;
  color: #ffffff;
  margin: 0 auto;
  font-family: Josefin Sans;

  animation: Animation 500ms;

  span {
    display: inline-block;
    margin-bottom: .5rem;
  }
}

.usprof-lbl-title-first {
  color: #ffffff;
  font: normal normal 300 21px/25px Josefin Sans;
  letter-spacing: 2.52px;
  text-transform: uppercase;
}

.usprof-lbl-title-last {
  margin-top: -20px;
  color: #ffffff;
  font: normal normal normal 88px/92px BrisaRegular;
}

.pg-usprof-div-save {
  width: 100%;
  text-align: center;
  margin-top: 20px;
}

.pg-usprof-lbl-function {
  width: 100%;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: 50px;
  font: normal normal 300 18px/25px Josefin Sans;
  letter-spacing: 2.52px;
  text-transform: uppercase;
  color: #ffffff;
}

.pg-usprof-lbl-info {
  width: 100%;
  font: normal normal 300 12px/20px Josefin Sans !important;
  text-align: center;
}

.pg-usprof-lbl-inputs {
  margin-top: 10px;
  margin-bottom: 3px;
}

.pg-usprof-btn-visible {
  opacity: 1;
  transition: opacity 0.2s linear;
}

.pg-usprof-btn-hidden {
  opacity: 0;
  transition: opacity 0.2s linear;
}