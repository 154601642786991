@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@200&display=swap');


.pr-ca-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    height: 100vh;
    color: #fff;
    overflow-y: hidden;
    height: auto;
}

.pr-ca-carousel-content {
    width: 75vw;
    flex-direction: column;
    text-align: left;
    font: normal normal 300 16px/22px Josefin Sans;
    color: #ffffff;
    display: flex;
    margin: 10px auto 50px;
}

.pr-ca-img-content {
    width: 100%;
    margin-top: 15px !important;
    margin-bottom: 15px !important;
    margin: 0 auto;
    max-width: 600px;
}

.pr-ca-lbl-reference {
    font: normal normal 300 12px/22px Josefin Sans;
    color: #ffffff;
    margin-bottom: .5rem;
}

.pr-ca-lbl-product-title {
    text-align: left;
    font: normal normal 600 16px/22px Josefin Sans;
    margin-bottom: 30px;
}

.pr-ca-lbl-product-description {
    font: normal normal 300 16px/22px Josefin Sans;
    color: #ffffff;
    margin-bottom: .5rem;
}

.pr-ca-lbl-sizes {
    text-align: left;
    font: normal normal 300 13px/22px Josefin Sans;
    color: #ffffff;
    margin-bottom: .5rem;
}

.pr-ca-div-details {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;

    .pr-ca-lnk-details {
        padding: 5px 5px 5px 20px;
        background-color: transparent;
        border: none !important;
        color: #ffffff;
        font: normal normal 300 13px/16px Josefin Sans;
        letter-spacing: 1.92px;
        text-transform: uppercase;
        
        &:focus {
            outline: none;
            box-shadow: none !important;
            border: none;
        }
        
        &:hover {
            text-decoration: none;
            color: #FFFFFF;
        }
        
        &::before {
            content: '+';
            position: absolute;
            margin-top: -2px;
            margin-left: -15px;
            font-size: 21px;
        }
    }
}


.carousel-indicators {
    //display: none !important;
    margin-top: 30px;
    bottom: -25px !important;

    li {
        width: 10px !important;
        height: 10px !important;
        border-radius: 100%;
    }
}

.pr-ca-indicator {
    bottom: 0px !important;
}

/*
.active+.carousel-item {
    display: block !important;
    margin-left: 83%;

    Label {
        opacity: 0;
    }
}

/*show 3 items */
/*.carousel-inner .active+.carousel-item {
    display: block !important;
    margin-left: 83%;

    Label {
        opacity: 0;
    }
}

.carousel-inner .carousel-item.active:not(.carousel-item-right):not(.carousel-item-left),
.carousel-inner .carousel-item.active:not(.carousel-item-right):not(.carousel-item-left)+.carousel-item,
.carousel-inner .carousel-item.active:not(.carousel-item-right):not(.carousel-item-left)+.carousel-item+.carousel-item {
    transition: none !important;
}

.carousel-inner .carousel-item-next,
.carousel-inner .carousel-item-prev {
    position: relative !important;
}

.carousel-inner .active.carousel-item+.carousel-item+.carousel-item+.carousel-item {
    position: absolute !important;
    top: 0 !important;
    right: -33.3333% !important;
    z-index: -1 !important;
    display: block !important;
    visibility: visible !important;
}

/* left or forward direction 
.active.carousel-item-left+.carousel-item-next.carousel-item-left,
.carousel-item-next.carousel-item-left+.carousel-item,
.carousel-item-next.carousel-item-left+.carousel-item+.carousel-item,
.carousel-item-next.carousel-item-left+.carousel-item+.carousel-item+.carousel-item {
    position: relative !important;
    transform: translate3d(-100%, 0, 0) !important;
    visibility: visible !important;
}

/* farthest right hidden item must be abso position for animations 
.carousel-inner .carousel-item-prev.carousel-item-right {
    position: absolute !important;
    top: 0 !important;
    left: 0 !important;
    z-index: -1 !important;
    display: block !important;
    visibility: visible !important;
}

/* right or prev direction 
.active.carousel-item-right+.carousel-item-prev.carousel-item-right,
.carousel-item-prev.carousel-item-right+.carousel-item,
.carousel-item-prev.carousel-item-right+.carousel-item+.carousel-item,
.carousel-item-prev.carousel-item-right+.carousel-item+.carousel-item+.carousel-item {
    position: relative !important;
    transform: translate3d(100%, 0, 0) !important;
    visibility: visible !important;
    display: block !important;
    visibility: visible !important;
}
*/

.prdt-lbl-add-to-product {
    width: auto;
    padding-top: 30px;
    font: normal normal 300 16px/16px Josefin Sans;
    letter-spacing: 1.92px;
    text-transform: uppercase;
    color: #FFFFFF;
    text-align: center !important;
    line-height: 20px;
    z-index: 500;

    label {
        width: 100%;
        white-space: pre-wrap;
    }
}