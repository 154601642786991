.component-project-search-FadeInUp {
    -webkit-animation: TransitionFadeInUp 0.5s ease;
    animation: TransitionFadeInUp 0.5s ease;
}

.component-project-search-FadeInOut {
    -webkit-animation: TransitionFadeOutDown 0.5s ease-out;
    animation: TransitionFadeOutDown 0.5s ease-out;
}

.component-project-div-content {
    position: fixed;
    bottom: 0;
    width: 90vw;
    background: #000000;
    padding: 10px 20px;
    border: 1px solid #707070;
    z-index: 500;
    min-height: 65vh;
    border-top-left-radius: 35px;
    border-top-right-radius: 35px;
    transition: height 2s;

    a,
    a:hover,
    a:active,
    a:visited,
    a:focus {
        text-decoration: none;
        color: #ffffff !important;
    }

}

.component-project-div-header {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #707070;
    padding-bottom: 10px;

    span {
        width: 90%;
        padding-left: 10%;
        font: normal normal 300 14px/14px Josefin Sans;
    }

    svg {
        margin-top: -6px;
        margin-right: 5px;
        stroke-width: 2px !important;
        cursor: pointer;
    }

    div {
        width: 10%;
        text-align: right;
    }
}

.component-project-div-form {
    width: 100%;
    padding: 0px 0px 10px 0px;
    justify-content: center;
    margin-top: 20px;
    text-align: left;

    span {
        font: normal normal 300 16px/19px Josefin Sans;
        letter-spacing: 1.92px;
        margin-bottom: .5rem;
    }

    input {
        font: normal normal 300 16px/19px Josefin Sans;
    }
}

.component-project-add {
    font: normal normal 300 16px/19px Josefin Sans;
    letter-spacing: 1.92px;
    color: #ffffff;
    background: transparent;
    border: none;
    text-transform: uppercase;
    margin-top: 40px;
}

.component-project-div-validator {
    display: flex;
    flex-direction: column;
    height: 30px;
    font: normal normal 300 14px/14px Josefin Sans !important;
    text-align: center;
    padding: 10px;
}

.component-project-lbl-validator {
    height: auto;
    font: normal normal 300 14px/14px Josefin Sans !important;
    letter-spacing: 0px !important;
}