.search-rs-div-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0 auto;
    width: 85%;
}

.search-rs-div-header {
    background: #000000;
    width: 100vh;
    height: 120px;
    overflow: hidden;
}

.search-rs-label-header {
    font: normal normal 600 18px/19px Josefin Sans;
    letter-spacing: 1.92px;
    color: #FFFFFF;
    text-transform: uppercase;
    opacity: 1;
    padding: 10px 0;
}

.search-rs-div-options {
    border-radius: 21px;
    vertical-align: middle;
    margin: 0 5px;

    width: auto !important;
    height: 45px;
    max-height: 45px !important;
    background: #000000;
    border: 1px solid #707070;

    padding: 7px 5px;

    svg {
        margin-right: 5px;
    }
}

.search-rs-div-options-category {
    background: #707070;
    border: 1px solid #000000;
}

.search-rs-lbl-cards {
    padding: 0 10px 0 13px;
    width: auto;
    font: normal normal 400 16px/19px Josefin Sans;
    letter-spacing: 1.92px;
    color: #FFFFFF;
}

.search-rs-div-products {
    padding: 20px 0 0 0;
    height: auto;
    background: #000000;
    display: flex;
    float: left;

    &:last-child {
        padding-bottom: 20px;
    }

    a {
        width: 100%;
        color: #FFFFFF;
        font: normal normal 200 16px Josefin Sans;
        letter-spacing: 2.4px;
        text-transform: uppercase;
        opacity: 1;
        cursor: pointer;
        margin-top: 5px;
    }

    a,
    a:hover,
    a:active,
    a:visited,
    a:focus {
        text-decoration: none;
        color: white;
    }
}

.search-rs-div-product-detail {
    width: 100vw;
    display: flex;
    float: none;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding-left: 10px;
}

.search-rs-lbl-product-reference {
    font: normal normal 300 13px/17px Josefin Sans;
    letter-spacing: 1.38px;
    text-transform: uppercase;
}

.search-rs-lbl-product-title {
    font: normal normal normal 15px/19px Josefin Sans;
    letter-spacing: 1.92px;
    text-transform: uppercase;
}

.search-rs-lbl-product-btn {
    padding: 5px 5px 5px 20px;
    background-color: transparent;
    border: none !important;
    color: #ffffff;
    font: normal normal 300 13px/16px Josefin Sans;
    letter-spacing: 1.92px;
    text-transform: uppercase;

    &:before {
        content: '+';
        position: absolute;
        margin-top: -2px;
        margin-left: -15px;
        font-size: 21px;
    }
}

.search-rs-img {
    max-width: 150px;
}

.search-rs-skeleton {
    width: 85%;
    margin-left: 7%;
}