@keyframes defaultAnimation {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.pg-lg-div-content {
  @import url("https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@300&display=swap");
  @import url("https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@600&display=swap");
  @import url("https://fonts.googleapis.com/css2?family=Dancing+Script&display=swap");

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 80% !important;
  height: calc(100vh - 100px) !important;
  max-width: 500px !important;
  color: #fff !important;
  margin: 0 auto !important;
  border: none !important;
  overflow-y: auto;

  animation: defaultAnimation 500ms !important;

  form {
    width: 100% !important;
    text-align: center !important;
  }

  h3 {
    margin: 0 auto 10px !important;
    font: normal normal 600 18px/22px Josefin Sans !important;
    text-transform: uppercase !important;
  }

  .pg-lg-lbl-title {
    width: 100%;
    margin-bottom: .5rem;
    text-align: center !important;
    color: #ffffff !important;
    font: normal normal normal 60px/60px BrisaRegular !important;
  }

  span {
    font: normal normal 600 16px/22px Josefin Sans;
    margin-top: 10px;
    margin-bottom: .5rem;
    display: inline-block;
  }
}

.pg-lg-div-type-login {
  @import url("https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@300&display=swap");
  @import url("https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@600&display=swap");

  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: -30px;
  width: 100%;
  height: 90vh;
  max-width: 500px;
  background: #000;
  color: #fff;

  h3 {
    margin-top: 0;
  }

  label {
    margin-top: 0;
  }
}

@media only screen and (max-width: 360px) {
  .pg-lg-btn-acessar {
    margin-top: 10px !important;
  }
}

.pg-lg-div-signup {
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;

  a {
    margin: 5px 0;
    padding: 5px;
    cursor: pointer;
    font: normal normal 300 16px/19px Josefin Sans;
    color: #fff;

    &:hover {
      color: #fff;
    }
  }
}

.pg-lg-div-forgot-password {
  a {
    font: normal normal 300 16px/19px Josefin Sans;
    color: #fff;
  }
}

.pg-lg-div-animate-intro {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background: #000000;
  opacity: 1;
  animation: 0.5s linear 4s 1 both animateIntro;
}

@keyframes animateIntroLogo {
  0% {
    opacity: 0;
    transform: scale(2) translateY(25vh);
  }

  25% {
    opacity: 1;
    transform: scale(2) translateY(25vh);
    transition: opacity 1s;
  }

  100% {
    opacity: 1;
    transform: scale(1) translateY(40px);
    transition: transform 2s 1s;
  }
}

.pg-lg-div-animate-intro-logo {
  background: url("../../assets/elettromec.svg") no-repeat;
  width: 146px;
  height: 21px;
  display: flex;
  margin: 0 auto;
  animation: 3s ease 1s 1 both animateIntroLogo;
}

.pg-lg-div-header {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 30px;
  text-align: left;
  background: #000;
  margin-top: 15px;
  margin-left: 0px;

  a {
    color: #fff !important;
  }
}

.pg-lg-div-password {
  width: 100%;
  color: #ffffff;
  font: normal normal 300 16px/22px Josefin Sans;
  position: relative;
  display: flex;

  i {
    position: absolute;
    right: 5%;
    top: 30%;
    cursor: pointer;
  }
}

.pg-lg-btn {
  border-radius: 50px;
  padding: 18px;
  font: normal normal bold 18px/22px Josefin Sans;
  letter-spacing: 1.92px;
  color: #030303;
  background: #fff;
  border: none;
  width: 100%;
  height: 52px;

  &:hover {
    background: #fff;
    color: #030303;
    text-decoration: none;
  }
}

.pg-lg-btn-acessar {
  margin-top: 20px;

  &:hover {
    background: #fff !important;
    color: #030303 !important;
    text-decoration: none !important;
  }
}

.pg-lg-svg-spinner {
  margin-top: 20px !important;  
}

.pg-lg-btn-consumidor {
  background: none;
  border: none;
  color: #fff;
  text-align: center;
  padding: 12px !important;
  margin: 5px auto 0;
  display: block;
  letter-spacing: 2.4px;
  font: normal normal 300 16px/16px Josefin Sans !important;

  &:hover {
    background: none !important;
    color: #fff !important;
    text-decoration: none !important;
  }
}

.pg-lg-input {
  height: 54px;
  background: #000000;
  border-radius: 50px;
  border: 1px solid #707070;
  padding: 15px;
  text-align: center;
  width: 100%;
  color: #ffffff;
  font: normal normal 300 16px/22px Josefin Sans;
  outline: none;
}

@keyframes animateIntro {
  0% {
    opacity: 1;
    z-index: 9999;
  }

  100% {
    opacity: 0;
    z-index: -1;
  }
}

.pg-lg-div-animate-intro {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background: #000000;
  opacity: 1;

  animation: 0.5s linear 4s 1 both animateIntro;
}

@keyframes animateIntroLogo {
  0% {
    opacity: 0;
    transform: scale(2) translateY(25vh);
  }

  25% {
    opacity: 1;
    transform: scale(2) translateY(25vh);
    transition: opacity 1s;
  }

  100% {
    opacity: 1;
    transform: scale(1) translateY(40px);
    transition: transform 2s 1s;
  }
}

.pg-lg-div-animate-intro-logo {
  background: url("../../assets/elettromec.svg") no-repeat;
  width: 146px;
  height: 21px;
  display: flex;
  margin: 0 auto;

  animation: 3s ease 1s 1 both animateIntroLogo;
}

.pg-login-label-info {
  font: normal normal 300 14px/14px Josefin Sans !important;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 10px;
}

.pg-lg-hr {
  display: flex;
  width: 15vh;
  height: 1px;
  border: none;
  background: rgba(255, 255, 255, 0.5);
  //border-top: 1px solid red;
}