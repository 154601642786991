.ft-lbl-title {
    display: inline-block;
    font: normal normal 600 16px/19px Josefin Sans;
    letter-spacing: 1.92px;
    color: #ffffff;
    text-transform: uppercase;
    line-height: 0px;
    margin-top: 30px;
    margin-bottom: .5rem;

    &:first-child {
        margin-top: 20px;
        margin-bottom: 10px;
    }
}

.ft-div-content {
    height: 330px;
    width: 100%;
    padding-left: 30px;
    line-height: 25px;
    margin-top: 65px;
}

.ft-lbl-address {
    font: normal normal 100 14px Josefin Sans;
    letter-spacing: 0px;
    color: #ffffff;
    line-height: 0px;
    margin-bottom: .5rem;
}

.ft-div-icons {
    height: 40px;
    color: #ffffff;

    img {
        margin-right: 10px;
    }
}

.ft-lbl-subtitle {
    font: normal normal 400 14px/18px Josefin Sans;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
    margin-left: 10px;

    &:hover{
        color: #ffffff;
    }
}


.ft-hr {
    position: absolute;
    margin-top: 50px;
    width: 100%;
    background: rgba(255, 255, 255, 0.15);
}

.footer-icons {
    margin-right: 10px;
    margin-top: -15px;
    margin-bottom: -10px;
}