.pg-rg-label-info {
  width: 100%;
  font: normal normal 300 18px/20px Josefin Sans !important;
  text-align: center;
  margin-top: 5px;
  margin-bottom: .5rem;
}

.pg-rg-input {
  height: 54px;
  background: #000000;
  border-radius: 50px;
  border: 1px solid #707070;
  padding: 15px;
  width: 100%;
  color: #ffffff;
  font: normal normal 300 16px/22px Josefin Sans;
  outline: none;
}

.pg-rg-svg-spinner {
  margin-top: 20px !important;
  text-align: center;
}

.pg-rg-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.pg-rg-div-header {
  display: flex;
  align-items: flex-start;
  width: 100%;
  background: #000;
  margin-top: 30px;
  margin-bottom: 10px;

  button {
    background: transparent;
    color: #fff !important;
    border: none;
    width: auto;
    padding: 0 5px;
    margin-top: 0;
    margin-left: 0px !important;

    &:hover {
      background: transparent;
    }
  }

  h3 {
    margin: 0 auto;
  }
}


@keyframes Animation {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.pg-rg-div-content {
  @import url("https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@300&display=swap");
  @import url("https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@600&display=swap");

  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80%;
  max-width: 500px;
  text-align: left;
  background: #000;
  color: #fff;
  margin: 0 auto;

  animation: Animation 500ms;

  form {
    width: 100%;
    text-align: left;
    padding-bottom: 50px;
  }

  h3 {
    font: normal normal 600 20px/22px Josefin Sans;
    margin-bottom: 20px;
  }

  input {
    background: black;
    border-radius: 50px;
    border: 1px solid #707070;
    padding: 15px;
    width: 100%;
    color: #fff;
    font: normal normal 300 16px/22px Josefin Sans;
    outline: none;
  }

  select {
    color: #ffffff;
    border-radius: 26px;
    height: 52px;
    background: black;
    border: 1px solid #707070;
    padding: 15px;
    width: 100%;
    font: normal normal 300 16px/22px Josefin Sans;
    outline: none;
    -moz-appearance: none;
    /* Firefox */
    -webkit-appearance: none;
    /* Safari and Chrome */
    appearance: none;
  }

  button {
    border-radius: 50px;
    padding: 18px;
    margin-top: 40px;
    font: normal normal 600 16px/22px Josefin Sans;
    letter-spacing: 1.92px;
    color: #030303;
    background: #ffffff;
    width: 100%;
    border: none;

    &:hover {
      text-decoration: none;
      color: #000;
      background: #fff;
    }
  }

  span {
    margin-bottom: .5rem;
    display: inline-block;
  }

  a {
    bottom: 30px;
    font: normal normal 600 16px/19px Josefin Sans;
  }

  div {
    h3 {
      text-transform: uppercase;
    }

    a {
      margin: 0 0 30px 0;
      cursor: pointer;
    }
  }
}