.project-div-content {
  display: flex;
  flex-direction: column;
  width: 80%;
  max-width: 500px;
  text-align: left;
  background: #000000;
  color: #ffffff;
  margin: 0 auto;
  font-family: Josefin Sans;

  animation: Animation 500ms;

  a,
  a:hover,
  a:active,
  a:visited,
  a:focus {
    text-decoration: none !important;
    color: #ffffff !important;
    cursor: pointer;
  }
}

.project-div-header {
  display: flex;
  flex-direction: column;
  vertical-align: middle;
  width: 100%;
  margin-top: 20px;
  padding: 5px;

  button:first-child {
    font: normal normal 600 16px/19px Josefin Sans;
    letter-spacing: 1.92px;
    color: #ffffff;
    background: transparent;
    border: none;
    text-transform: uppercase;
    margin-bottom: 40px;
    width: 120px;
    margin-left: -10px;
  }

  svg {
    margin-top: -6px;
    margin-right: 5px;
    stroke-width: 1px;
  }
}

.project-lbl-novo {
  color: #ffffff;
  font: normal normal 300 21px/25px Josefin Sans;
  letter-spacing: 2.52px;
  text-transform: uppercase;
}

.project-lbl-projeto {
  margin-top: -20px;
  color: #ffffff;
  font: normal normal normal 88px/92px BrisaRegular;
}

.project-div-form {
  padding: 0px 0px 10px 0px;
  text-align: left;

  span {
    font: normal normal 300 16px/19px Josefin Sans;
    letter-spacing: 1.92px;
    color: #ffffff;
    margin-top: 20px !important;
    margin-bottom: 0.5rem;
    display: inline-block;
  }
}

.project-lbl-title-first {
  margin-bottom: 0.5rem;
  color: #ffffff;
  font: normal normal 300 21px/25px Josefin Sans;
  letter-spacing: 2.52px;
  text-transform: uppercase;
}

.project-lbl-title-last {
  margin-top: -20px;
  margin-bottom: 0.5rem;
  color: #ffffff;
  font: normal normal normal 88px/92px BrisaRegular;
}

.project-a-form {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;

  a {
    padding: 10px 0;
  }

  button {
    margin: 10px 0;
  }
}

.project-lnk-save {
  font: normal normal 600 16px/19px Josefin Sans;
  letter-spacing: 1.92px;
  color: #ffffff;
  background: transparent;
  border: none;
  text-transform: uppercase;
  margin-bottom: 10px;
}

.project-lnk-create {
  font: normal normal 300 16px/19px Josefin Sans;
  letter-spacing: 1.92px;
  color: #ffffff;
  text-transform: uppercase;
}

.project-lnk-cancel {
  font: normal normal 300 14px/14px Josefin Sans;
  letter-spacing: 1.56px;
  background: transparent;
  border: none;
  color: #ffffff;
  text-transform: uppercase;
}

.project-div-edit {
  display: flex;
  flex-direction: row;
  width: 100%;
  border-top: solid 1px #000000;
  margin-top: -1px !important;
  margin-left: 10px;
  font: normal normal 300 16px/22px Josefin Sans;

  button {
    display: flex;
    background: transparent;
    color: #ffffff;
    border: none;
    margin-left: 5px;
    margin-right: 15px;
    margin-bottom: 0.5rem;

    svg {
      margin: 0 5px;
    }
  }
}

.new-project-div-list {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: solid 1px #ffffff;

  &:first-child {
    border-top: none;
  }
}

.new-project-div-list-info {
  margin: 17px 7px 17px 17px;
  display: flex;
  flex-direction: column;
  background: transparent;
  border: none;
}

.new-project-div-form {
  text-align: left;
  border-radius: 35px;
}

.new-project-div-item {
  border-bottom: 1px solid #ffffff;

  &:last-child {
    border: none;
  }
}

.new-project-div-view {
  cursor: pointer;
  text-align: center;
  padding: 5px;
  margin-right: 10px;
}

.new-project-lbl-title {
  cursor: pointer;
  font: normal normal normal 16px/19px Josefin Sans;
  letter-spacing: 1.92px;
  color: #ffffff;
  text-align: left;
  text-transform: uppercase;
  margin-bottom: 0.5rem;
}

.new-project-lbl-products {
  cursor: pointer;
  font: normal normal 300 16px/19px Josefin Sans;
  letter-spacing: 1.92px;
  color: #ffffff;
  margin-top: -5px;
}

.new-project-div-validator {
  display: flex;
  flex-direction: column;
  height: 25px;
  font: normal normal 300 14px/14px Josefin Sans !important;
  text-align: center;
  margin-top: -10px;
}

.new-project-lbl-validator {
  margin-bottom: 0.5rem;
  height: auto;
  font: normal normal 300 14px/14px Josefin Sans !important;
  letter-spacing: 0px !important;
}

.vw-project-div-list-info {
  height: 100%;
  width: 90%;
  display: flex;
  flex-direction: column;
}

.vw-project-lbl-project-title {
  font: normal normal normal 20px/24px Josefin Sans;
  letter-spacing: 2.4px;
  color: #ffffff;
  text-transform: uppercase;
}

.vw-project-lbl-client {
  font: normal normal 300 16px/18px Josefin Sans;
  letter-spacing: 2.4px;
  color: #ffffff;
  text-transform: uppercase;
  margin-bottom: 0.5rem;
}

.vw-project-lbl-products {
  font: normal normal 300 16px/19px Josefin Sans;
  letter-spacing: 1.92px;
  color: #ffffff;
  margin-top: 10px;
}

.vw-project-div-view {
  cursor: pointer;
  text-align: center;
  height: 100%;
  width: 10%;

  svg {
    stroke-width: 1.5;
  }
}

.vw-project-div-project {
  display: flex;
  flex-direction: row;
}

.vw-project-div-list {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin: 5px 0;
  padding: 10px 0;
}

.vw-project-list-img {
  display: flex;
  align-items: center;
  width: 25%;

  img {
    max-width: 100%;
  }
}

.vw-project-list-product-name {
  width: 65%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 0 0 0 5%;

  label {
    margin: 0;
  }
}

.vw-project-sgv-delete {
  display: flex;
  cursor: pointer;
  align-items: center;
  width: 10%;
  padding-left: 6px;

  svg {
    stroke-width: 1.5;
  }
}

.vw-project-lbl-ref {
  font: normal normal 300 13px/17px Josefin Sans;
  letter-spacing: 1.38px;
  color: #ffffff;
}

.vw-project-lbl-title {
  font: normal normal normal 15px/19px Josefin Sans;
  letter-spacing: 1.92px;
  color: #ffffff;
}

.vw-project-hr {
  margin-left: -100%;
  width: 300%;
  height: 1px;
  border: none;
  background: rgba(255, 255, 255, 0.3);
}

.vw-project-a-add-product {
  display: flex;
  flex-direction: column;
  align-items: center;

  button {
    padding: 10px 0;
    font: normal normal 300 16px/19px Josefin Sans;
    letter-spacing: 1.92px;
    background: transparent;
    color: #ffffff;
    border: none;
    text-transform: uppercase;
  }
}

.vw-project-modal {
  background: #000000 !important;
  color: #ffffff !important;

  font: normal normal 300 16px/19px Josefin Sans;
  letter-spacing: 1.92px;
  text-transform: uppercase;

  border: 1px solid rgba(255, 255, 255, 0.5) !important;
  border-radius: 35px !important;
}

.vw-project-modal-header {
  text-align: center;
}

@media screen and (max-width: 767px) {
  .vw-project-lbl-ref {
    font: normal normal 300 9px/13px Josefin Sans;
  }

  .vw-project-lbl-title {
    font: normal normal normal 11px/15px Josefin Sans;
  }
}
