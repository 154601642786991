@keyframes Animation {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.pg-fg-pw-div-content {
  @import url("https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@300&display=swap");
  @import url("https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@600&display=swap");
  @import url("https://fonts.googleapis.com/css2?family=Dancing+Script&display=swap");

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  width: 80%;
  height: calc(100vh - 100px);
  max-width: 500px;
  text-align: left;
  background: #000;
  color: #fff;
  margin: 0 auto;

  animation: Animation 500ms;

  form {
    width: 100%;
    text-align: center;
  }

  h3 {
    margin: 0 auto 20px;
    font: normal normal 600 20px/22px Josefin Sans;
    text-transform: uppercase;
  }

  .lbl-title {
    margin-bottom: 100px;
    text-align: center;
    color: white;
    font-size: 50px;
    font: normal normal normal 78px/82px BrisaRegular;
  }

  span {
    font: normal normal 400 16px/22px Josefin Sans;
    margin-top: 10px;
    margin-bottom: 5px;
    display: inline-block;
  }

  button {
    width: 100%;
    height: 52px;
    background: #ffffff;
    border-radius: 50px;
    font: normal normal bold 18px/22px Josefin Sans;
    letter-spacing: 1.92px;
    color: #030303;
    margin-top: 40px;
    border: none;

    &:hover {
      background: #fff;
      color: #030303;
      text-decoration: none;
    }
  }

  div {
    a {
      margin: 0 0 30px 0;
      cursor: pointer;
    }
  }
}

.pg-fg-pw-label-info {
  font: normal normal 300 12px/20px Josefin Sans !important;
  text-align: center;
  margin-top: 10px;
}

.pg-fg-pw-input {
  height: 54px;
  background: #000000;
  border-radius: 50px;
  border: 1px solid #707070;
  padding: 15px;
  width: 100%;
  color: #ffffff;
  text-align: center;
  font: normal normal 300 16px/22px Josefin Sans;
  outline: none;
}

.pg-fg-pw-div-inputs {
  width: 100%;
  color: #ffffff;
  font: normal normal 300 16px/22px Josefin Sans;
  position: relative;
  display: flex;

  i {
    position: absolute;
    right: 5%;
    top: 30%;
    cursor: pointer;
  }
}

.pg-fg-pw-div-header {
  display: flex;
  align-items: center;
  width: 100%;

  button {
    color: #ffffff !important;
    background: transparent;
    border: none;
    width: auto;
    height: auto;
    margin-top: 0;
    margin-bottom: 20px;
    border-radius: 0;

    &:hover {
      background: transparent;
      color: #ffffff;
    }
  }
}

.pg-lg-div-signup {
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;

  a {
    margin: 5px 0;
    padding: 5px;
    cursor: pointer;
    font: normal normal 300 16px/19px Josefin Sans;
    color: #fff;

    &:hover {
      color: #fff;
    }
  }
}
