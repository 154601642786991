.comp-confirmation-header {
    color: #ffffff !important;

    font: normal normal 300 16px/19px Josefin Sans;
    letter-spacing: 1.2px;
    text-transform: uppercase;

    label {
        display: flex;
        justify-content: center;
    }

    border-bottom: 1px solid rgba(255, 255, 255, 0.5) !important;
}

.comp-confirmation-content {
    background: #000000 !important;
    color: #ffffff !important;

    font: normal normal 300 16px/19px Josefin Sans;
    letter-spacing: 1.2px;

    border: 1px solid rgba(255, 255, 255, 0.5) !important;
    border-radius: 35px !important;
}

.comp-confirmation-body {
    border: none !important;
    text-transform: none !important;
    font: normal normal 300 16px/19px Josefin Sans;
    letter-spacing: 1.92px;
}

.modal-footer {
    border-top: 1px solid rgba(255, 255, 255, 0.5) !important;
}

.comp-confirmation-footer {
    color: #ffffff !important;

    font: normal normal 300 14px/16px Josefin Sans;
    letter-spacing: 1px;

    button {
        background: #000000;
        border: none;
        color: #ffffff;
        text-align: left;
        margin: 0 10px;
        letter-spacing: 1px;
        font: normal normal 300 18px/18px Josefin Sans !important;

        &:hover {
            background: none !important;
            color: #fff !important;
            text-decoration: none !important;
        }
    }
}

.comp-video-iframe-yt {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding-bottom: 20px;
    width: 100%;
    height: 100%;
    max-height: 500px;
    max-width: 850px;
}

.comp-video-iframe-container {
    display: block;
    position: relative;
    width: 100%;
    padding-bottom: 56.25%;
    // height: 100%;
    // min-height: 308px;
    max-height: 500px;
    max-width: 850px;
}