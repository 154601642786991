.pg-pw-purchaseDate {
  border: 1px solid #707070 !important;
  border-radius: 35px;
  padding: 10px 10px 10px 5px;
  min-height: 54px;

  input {
    height: 22px;
    padding: 0px;
    border: none !important;
    border-radius: 15px;
    background: red;
  }
}

.pg-pw-purchaseDate-invalid {
  border: none !important;
  padding: 0;

  input {
    height: 54px;
    border-radius: 35px;
    border: 1px solid #FF0000 !important;
  }
}

.pg-pw-div-product-add {
  display: flex;
  flex-direction: row;
}

.pg-pw-table-products {
  font: normal normal 200 16px/16px Josefin Sans;
  color: #FFFFFF;

  th,
  td {
    padding: 3px 0;

    input {
      text-align: center;
    }

    svg:last-child {
      margin-left: 10px;
    }
  }

  svg {
    cursor: pointer;
  }
}

.pg-pw-btn-add-product {
  padding: 5px 5px 5px 20px;
  background-color: transparent !important;
  border: none !important;
  color: #ffffff !important;
  font: normal normal 300 13px/16px Josefin Sans;
  letter-spacing: 1.92px;
  text-transform: uppercase;
  margin: 0 !important;

  &:focus {
    outline: none;
    box-shadow: none !important;
    border: none;
  }

  &:hover {
    text-decoration: none;
    color: #FFFFFF;
  }

  &::before {
    content: '+';
    position: absolute;
    margin-top: -2px;
    margin-left: -15px;
    font-size: 21px;
  }
}

.pg-pw-table-center-text {
  text-align: center;
}

.pg-rg-input {
  height: 54px;
  background: #000000;
  border-radius: 50px;
  border: 1px solid #707070;
  padding: 15px;
  width: 100%;
  color: #ffffff;
  font: normal normal 300 16px/22px Josefin Sans;
  outline: none;

  transition: 0.4s;
}

.pg-rg-input-product-quantity {
  padding: 5px !important;
  height: 30px !important;
  width: 60px !important;
  margin: 0 5px;
}

.expandFieldProduct {
  height: 244px;
}

.customDatePickerWidth,
.customDatePickerWidth>div.react-datepicker-wrapper,
.customDatePickerWidth>div>div.react-datepicker__input-container .customDatePickerWidth>div>div.react-datepicker__input-container input {
  width: 100%;
}

.pg-rg-div-description {
  padding-top: 20px;
}

.pg-rg-div-description>p {
  //   padding-top: 20px;

  font: normal normal 100 16px/22px Josefin Sans;

  line-break: normal;
  overflow-wrap: break-word;
  word-wrap: break-word;
  hyphens: auto;
}

.pg-rg-div-description>p>h1>span {
  color: white;
  font: normal normal 300 21px/25px Josefin Sans;
  letter-spacing: 2.52px;
  text-transform: uppercase;
}

.pg-rg-div-description>p>h1 {
  font: normal normal normal 78px/52px BrisaRegular !important;
}

.container-radio-button {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}

.content-radio-button {
  display: inline-block;
  flex-direction: row;
  align-items: center;
  margin-left: 8px;
  justify-content: space-between;
  overflow-wrap: break-word;
  word-wrap: break-word;
  hyphens: auto;
}

.container-radio-button>.content-radio-button>span {
  margin-top: 5px;
  margin-left: 8px;
  font: normal normal 100 16px/22px Josefin Sans;
}

.pg-rg-content {
  height: 250%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.pg-rg-add-button>label {
  margin-left: 10px;
}

.pg-rg-div-header {
  display: flex;
  align-items: flex-start;
  width: 100%;
  background: #000;
  margin-top: 30px;
  margin-bottom: 10px;

  button {
    background: transparent;
    color: #fff !important;
    border: none;
    width: auto;
    padding: 0 5px;
    margin-top: 0;
    margin-left: 0px !important;

    &:hover {
      background: transparent;
    }
  }

  h3 {
    margin: 0 auto;
  }
}

@keyframes Animation {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}


.pg-rg-div-content-skeleton {
  display: flex;
  flex-direction: column;
  text-align: center;
  width: 80%;
  max-width: 500px;
  margin: auto auto;
}

.pg-pw-div-content {
  @import url("https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@300&display=swap");
  @import url("https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@600&display=swap");

  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80%;
  text-align: left;
  background: #000;
  color: #fff;
  margin: 0 auto;

  animation: Animation 500ms;

  form {
    width: 100%;
    display: flex;
    flex-direction: column;
    text-align: left;
  }

  h3 {
    font: normal normal 600 20px/22px Josefin Sans;
    margin-bottom: 20px;
  }

  span {
    font: normal normal 400 16px/22px Josefin Sans;
    margin-bottom: 5px;
    margin-top: 10px;
  }

  input {
    background: black;
    border-radius: 50px;
    border: 1px solid #707070;
    padding: 15px;
    width: 100%;
    color: #fff;
    font: normal normal 300 16px/22px Josefin Sans;
    outline: none;
  }

  input[type="radio"] {
    width: 20px;
    visibility: hidden;
    margin-right: 4px;
  }

  input[type="radio"]:after {
    width: 20px;
    height: 20px;
    border-radius: 15px;
    top: -2px;
    left: -1px;
    position: relative;
    background-color: black;
    content: "";
    display: inline-block;
    visibility: visible;
    border: 1px solid #707070;
  }

  .input-radio-invalid {
    &:after {
      border: 1px solid #FF0000 !important;
    }
  }

  input[type="radio"]:checked:after {
    width: 20px;
    height: 20px;
    border-radius: 15px;
    top: -2px;
    left: -1px;
    position: relative;
    background-color: white;
    content: "";
    display: inline-block;
    visibility: visible;
    border: 1px solid #707070;
  }

  select {
    color: #ffffff;
    border-radius: 26px;
    height: 52px;
    background: black;
    border: 1px solid #707070;
    padding: 15px;
    width: 100%;
    font: normal normal 300 16px/22px Josefin Sans;
    outline: none;
    -moz-appearance: none;
    /* Firefox */
    -webkit-appearance: none;
    /* Safari and Chrome */
    appearance: none;
  }

  button {
    border-radius: 50px;
    padding: 18px;
    margin-top: 40px;
    font: normal normal 600 16px/22px Josefin Sans;
    letter-spacing: 1.92px;
    color: #030303;
    background: #ffffff;
    width: 100%;
    border: none;

    &:hover {
      text-decoration: none;
      color: #000;
      background: #fff;
    }
  }

  a {
    bottom: 30px;
    font: normal normal 600 16px/19px Josefin Sans;
  }

  div {
    h3 {
      text-transform: uppercase;
    }

    a {
      margin: 0 0 30px 0;
      cursor: pointer;
    }
  }
}


.pg-pw-lbl-title {
  width: 80vw;
  max-width: 500px;
  margin-top: -20px;
  color: white;
  font-size: 50px;

  span {
    margin-bottom: .5rem;
    text-align: left;
    font: normal normal normal 88px/92px BrisaRegular !important;
  }
}

.DayPicker-Day .DayPicker-Day--disabled {
  background: red;
}