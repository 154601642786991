@import url("https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@200&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@600&display=swap");

@keyframes animacao {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

a,
a:hover,
a:active,
a:visited,
a:link,
a:focus {
  color: #ffffff !important;
}

.prdt-div-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  background: #000;
  min-height: 100vh;
  color: #fff;

  animation: animacao;
  animation-duration: 1s;
}

.prdt-div-3D-viewer {
  height: 58vh;

  iframe {
    margin: 0px;
    width: 100%;
    height: 100%;
  }
}

.prdt-div-header {
  width: 75%;
  background-color: #000;
  display: flex;
  margin: auto;
  height: 55px;
  text-align: left;
}

.prdt-div-carousel-content {
  width: 75%;
  flex-direction: column;
  text-align: left;
  font: normal normal 300 16px/22px Josefin Sans;
  letter-spacing: px;
  color: #ffffff;
  opacity: 1;
  display: flex;
  margin: auto;
  margin-bottom: 10px;
  height: 100%;
}

.prdt-lbl-reference {
  font: normal normal 300 13px/22px Josefin Sans;
  color: #ffffff;
  margin-bottom: 0px;
  margin-top: 25px;
}

.prdt-lbl-title {
  text-align: left;
  font: normal normal 600 16px/22px Josefin Sans;
  margin-bottom: 20px;
}

.prdt-lbl-description {
  font: normal normal 300 17px/22px Josefin Sans;
  margin-bottom: 20px;
}

.prdt-btn-back {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 10px;
  padding-top: 10px;

  button,
  button:hover,
  button:focus {
    display: flex;
    align-items: flex-start;
    border: none;
    width: fit-content;
    text-decoration: none;
    background: transparent;
    color: #ffffff;
    cursor: pointer;
  }

  svg {
    margin-right: 5px;
    stroke-width: 1px;
  }

  span {
    font: normal normal 600 16px/19px Josefin Sans;
    letter-spacing: 1.92px;
    text-transform: uppercase;
    margin: 0;
    cursor: pointer;
  }
}

.prdt-btn-pdf {
  position: absolute;
  top: 0;
  right: 0;
  width: 32px;
  height: 40px;
  overflow: hidden;

  svg {
    margin-right: 0;
  }
}

.prdt-tbl-data {
  width: 75%;
  margin-bottom: 15px;

  tr {
    height: 45px;
    border: 1px solid white;
  }

  td {
    text-align: left;
    //padding-left: 10px;
  }
}

.prdt-skeleton {
  width: 85%;
  margin-left: 7%;
  min-height: 100vh;
}

.prdt-td-icon {
  width: 40px;
  text-align: center !important;
  cursor: pointer;

  svg {
    vertical-align: baseline;
    width: 1.2em;
    height: 1.2em;
  }

  a,
  a:hover,
  a:active,
  a:visited,
  a:focus {
    text-decoration: none;
    color: white;
  }
}

.prdt-td-text {
  font: normal normal 400 14px/16px Josefin Sans;
  letter-spacing: 1.56px;
  color: #ffffff;
  text-transform: uppercase;
  cursor: pointer;

  a,
  a:hover,
  a:active,
  a:visited,
  a:focus {
    text-decoration: none;
    color: white;
  }

  label {
    margin: 0;
  }
}

.prdt-lbl-table-title {
  text-align: left;
  font: normal normal 600 13px/16px Josefin Sans;
  color: #ffffff;
  margin-bottom: 2px;
}

.prdt-lbl-table-details {
  text-align: left;
  font: normal normal 200 13px/16px Josefin Sans;
  color: #ffffff;
  margin-bottom: 5px !important;
}

.prdt-lbl-table-text-detail {
  text-align: left;
  font: normal normal 200 13px/16px Josefin Sans;
  color: #ffffff;
  margin-bottom: 5px !important;
  padding: 10px;
}

.prdt-hr {
  margin-top: -2px;
  margin-bottom: 10px;
  height: 0px;
  border: none;
  border-top: 1px solid rgba(255, 255, 255, 0.05);
}

.prdt-div-list-download {
}

.prdt-downloads-title {
  font: normal normal 400 15px/15px Josefin Sans;
  text-align: right;
  width: 100%;
  margin-right: 25%;
  margin-bottom: 10px;
  color: #ffffff;
}

.prdt-list-download {
  list-style: none;

  .prdt-downloads-title {
    margin-top: 20px;
    margin-bottom: -5px;
    text-align: left;
  }

  a,
  a:hover,
  a:active,
  a:visited,
  a:focus {
    font: normal normal 300 15px Josefin Sans;
    text-align: left;
    margin-bottom: 0px;
    color: #ffffff;
  }

  svg {
    margin-right: 10px;
  }

  li {
    padding: 5px 0;

    &:last-child {
      padding: 7px 0 0 0;
    }
  }
}

.prdt-lbl-text {
  font: normal normal 200 15px Josefin Sans;
  color: #ffffff;
  margin-bottom: 5px !important;
  padding: 12px;
}

.prdt-lbl-add-to-project {
  width: 100%;
  height: 60px;
  padding: 40px 0 20px;
  font: normal normal 300 13px/16px Josefin Sans;
  letter-spacing: 1.92px;
  text-transform: uppercase;

  button {
    padding: 5px 5px 5px 15px;
    margin: 0 auto;
    font: normal normal 300 13px/16px Josefin Sans;
    letter-spacing: 1.92px;
    text-align: center;
    text-transform: uppercase;
    color: #ffffff;
    background: transparent;
    border: none;

    &::before {
      content: "+";
      position: absolute;
      margin-top: -2px;
      margin-left: -15px;
      font-size: 21px;
    }
  }
}

.p-d-conteudo-carousel {
  position: relative;
  width: 75%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  margin-bottom: 10px;
  height: 100%;
  min-height: 308.25px;
  font: normal normal 400 18px/18px Josefin Sans;
}

.prdt-bg-overlay {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  margin: 0 auto;
  cursor: pointer;

  span {
    width: 100%;
    text-align: center;
    position: absolute;
    bottom: 5%;
    cursor: pointer;
  }

  svg {
    cursor: pointer;
    stroke-width: 1px;
    position: absolute;
  }
}

.p-d-img-conteudo {
  width: 100%;
  margin-bottom: 15px !important;
  margin: 0 auto;
  max-width: 850px;
}

.p-d-lbl-descricao-produto {
  font: normal normal 300 17px/22px Josefin Sans;
  margin-bottom: 20px;
}

.p-d-lbl-titulo-produto {
  text-align: left;
  font: normal normal 600 16px/22px Josefin Sans;
  margin-bottom: 20px;
}

.p-d-lbl-referencia {
  font: normal normal 300 13px/22px Josefin Sans;
  color: #ffffff;
  margin-bottom: 0px;
  margin-top: 25px;
}

.p-d-tb-dados {
  width: 75%;
  margin-bottom: 15px;

  tr {
    height: 50px;
    border: 1px solid white;
  }

  td {
    text-align: left;
    margin: 10px 0 10px 15px;
    //padding-left: 10px;
  }
}

.p-d-td-infos {
  font: normal normal 400 14px/16px Josefin Sans;
  letter-spacing: 1.56px;
  color: #ffffff;
  text-transform: uppercase;
  //padding-left: 10px !important;
}

.p-d-div-dados-ocultos {
  font: normal normal 200 16px Josefin Sans;
  padding: 10px;

  span {
    margin-top: 5px;
    margin-bottom: 0.5rem;
    display: inline-block;
  }
}

.p-d-div-link {
  width: 30px;
  border-right: none;
  text-align: center;
  text-decoration: none;
  color: white;
  margin: 0 auto;
  padding: 0 20px 0 10px;

  svg {
    vertical-align: baseline;
  }

  a,
  a:hover,
  a:active,
  a:visited,
  a:focus {
    text-decoration: none;
    color: white;
    margin-top: -5px;
  }
}

.prdt-iframe-container {
  display: block;
  position: relative;
  width: 100%;
  padding-bottom: 56.25%;
  // height: 100%;
  // min-height: 308px;
  max-height: 500px;
  max-width: 850px;
}

.prdt-iframe-yt {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding-bottom: 20px;
  width: 100%;
  height: 100%;
  max-height: 500px;
  max-width: 850px;
}

.carousel-item.active {
  div:not(.p-d-conteudo-carousel) {
    width: 75vw;
  }
}
