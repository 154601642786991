.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 300px;
}

.text {
  margin-top: 20px;
  font: normal normal 400 16px/22px Josefin Sans;
}

.icon {
  margin-top: 30px;
  border: 1px solid #ccc;
  border-radius: 50%;
  box-sizing: border-box;
  padding: 15px;
}

.button {
  border-radius: 50px;
  padding: 18px;
  font: normal normal bold 18px/22px Josefin Sans;
  letter-spacing: 1.92px;
  color: #030303;
  background: #fff;
  border: none;
  width: 80%;
  height: 52px;
  margin: 50px 10px 0;
}
