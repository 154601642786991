@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@200&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Dancing+Script&display=swap');

@keyframes animacao {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.ctprd-lbl-category-title {
    margin-bottom: .5rem;
    padding: 10px;
    font: normal normal 200 16px/22px Josefin Sans;
    color: #ffffff;
}

.ctprd-div-content {
    width: 100%;
    flex-direction: column;
    animation: animacao;
    animation-duration: 1s;
}

.ctprd-div-header {
    display: flex;
    flex-flow: column;
    padding: 40px 30px 0;
    height: 180px;
    position: relative;
    background: black;
    border: none;
}

.ctprd-div-content-data {
    width: 80%;
    display: flex;
    flex-direction: column;
    margin: auto;
    text-align: left;
}

.ctprd-div-category-item {
    background-repeat: no-repeat;
    background-position: center top;
    background-size: 100%;
}

.ctprd-div-category-gradient {
    background: rgb(0, 0, 0);
    background: linear-gradient(180deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0.9) 80%, rgba(0, 0, 0, 0.8) 100%);
}

.ctprd-category-button {
    background: transparent;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: white;
    border: 1px solid #272727;
    border-radius: 0px !important;
    height: 85px !important;
    padding: 0;
    width: 100%;

    svg {
        color: white;
        float: right;
        margin-right: 30px;
    }
}

.ctprd-skeleton {
    width: 90%;
    margin-left: 15px;
 }

.ctprd-button-label {
    @import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@300&display=swap');

    margin-left: 30px;
    margin-bottom: 0;
    color: white;
    font: normal normal 300 20px/24px Josefin Sans;
    letter-spacing: 2.4px;
    text-transform: uppercase;
    opacity: 1;
    cursor: pointer;
}

.ctprd-btn-see-more {
    background: transparent;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: white;
    border: 1px solid #272727;
    border-bottom: none;
    border-radius: 0px !important;
    height: 85px !important;
    padding: 0;
    width: 100%;
    font: normal normal 300 20px/24px Josefin Sans;
    text-align: center;
}

.ctprd-label-see-more {
    @import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@300&display=swap');

    width: 100%;
    color: #ffffff;
    font: normal normal 300 16px/19px Josefin Sans;
    letter-spacing: 2.4px;
    text-transform: uppercase;
    cursor: pointer;
}


.lbl-nossos {
    margin-bottom: .5rem;
    font: normal normal 300 21px/25px Josefin Sans;
    letter-spacing: 2.52px;
    text-transform: uppercase;
    color: #ffffff;
}

.lbl-produtos {
    margin-top: -20px;
    margin-bottom: .5rem;
    font: normal normal normal 88px/92px BrisaRegular;
    color: #ffffff;
}

.conteudo {
    margin-top: 15px;
    z-index: 0;
}