@import url("https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@300&display=swap%22");


.pg-ew-div-content {
  display: flex;
  flex-direction: column;
  width: 80%;
  text-align: left;
  background: #000;
  color: #fff;
  margin: 0 auto;
  font-family: Josefin Sans;

  animation: Animation 500ms;
}

.pg-ew-description {
  font: normal normal 300 16px/22px Josefin Sans;
}

.pg-ew-header {
  display: flex;
  flex-direction: column;
  vertical-align: middle;
  width: 100%;
  margin-top: 20px;
  padding: 5px;
}

.pg-ew-lbl-ew {
  color: #ffffff;
  font: normal normal normal 88px/92px BrisaRegular;
  margin-top: -20px;
  margin-bottom: .5rem;
}

.pg-ew-lbl-warranty {
  margin-bottom: .5rem;
  color: #ffffff;
  font: normal normal 300 21px/25px Josefin Sans;
  letter-spacing: 2.52px;
  text-transform: uppercase;
}