.project-div-content {
    display: flex;
    flex-direction: column;
    width: 80%;
    max-width: 1100px;
    color: #fff;
    margin: 0 auto;
    font-family: Josefin Sans;

    animation: Animation 500ms;

    a,
    a:hover,
    a:active,
    a:visited,
    a:focus {
        text-decoration: none !important;
        color: #ffffff !important;
        cursor: pointer;
    }
}


.project-div-header {
    display: flex;
    flex-direction: column;
    vertical-align: middle;
    width: 100%;
    margin-top: 20px;
    padding: 5px;

    a:first-child {
        font: normal normal 600 16px/19px Josefin Sans;
        letter-spacing: 1.92px;
        color: #FFFFFF;
        text-transform: uppercase;
        margin-bottom: 40px;
        width: 120px;
        margin-left: -10px;
    }
}

.project-lbl-novo {
    color: white;
    font-size: 50px;
    font: normal normal 300 21px/25px Josefin Sans;
    letter-spacing: 2.52px;
    text-transform: uppercase;
}

.project-lbl-projeto {
    margin-top: -20px;
    color: white;
    font-size: 50px;
    font: normal normal normal 88px/92px BrisaRegular;
}

.project-lbl-meus {
    color: white;
    font-size: 50px;
    font: normal normal 300 21px/25px Josefin Sans;
    letter-spacing: 2.52px;
    text-transform: uppercase;
}

.project-lbl-project {
    margin-top: -20px;
    color: white;
    font-size: 50px;
    font: normal normal normal 88px/92px BrisaRegular;
}

.share-pdf-div-list-info {
    height: 100%;
    width: 90%;
    display: flex;
    flex-direction: column;
}

.share-pdf-lbl-project-title {
    font: normal normal normal 20px/24px Josefin Sans;
    letter-spacing: 2.4px;
    color: #FFFFFF;
    text-transform: uppercase;
    opacity: 1;
}

.share-pdf-lbl-client {
    font: normal normal 300 16px/18px Josefin Sans;
    letter-spacing: 2.4px;
    color: #FFFFFF;
    text-transform: uppercase;
    opacity: 1;
}

.share-pdf-lbl-products {
    font: normal normal 300 16px/19px Josefin Sans;
    letter-spacing: 1.92px;
    color: #FFFFFF;
    margin-top: 10px;
}

.share-pdf-div-view {
    cursor: pointer;
    text-align: center;
    height: 100%;
    width: 10%;

    svg {
        stroke-width: 1.5;
    }
}

.share-pdf-div-project {
    display: flex;
    flex-direction: row;
}

.share-pdf-div-list {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    width: 100%;
    margin: 5px 0;
    padding: 10px 0;
}

.share-pdf-list-img {
    display: flex;
    align-items: center;
    flex: 1;

    img {
        max-width: 100%;
    }
}

.share-pdf-list-item {
    flex: 2;
    padding-left: 50px;
}

.share-pdf-tr {
    display: flex;
    width: 100%;
    margin-bottom: -1px;
}

.share-pdf-th,
.share-pdf-td {
    border: 1px solid #707070;
    padding: 5px 10px;
}

.share-pdf-th {
    flex: 1;
}

.share-pdf-td {
    flex: 2;
    margin-left: -1px;

    a {
        display: inline-flex;
        align-items: center;
        margin-right: 10px;

        svg {
            margin-right: 5px;
        }
    }
}

.share-pdf-list-product-name {
    width: 65%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: left;
    padding: 7% 0 0 5%;

    label {
        margin: 0;
    }
}

.share-pdf-ref {
    font: normal normal 300 9px/15px Josefin Sans;
    letter-spacing: 1.08px;
    color: #FFFFFF;
}

.share-pdf-title {
    height: auto;
    font: normal normal normal 11px/15px Josefin Sans;
    letter-spacing: 1.32px;
    color: #FFFFFF;
}

.share-pdf-description {
    margin: 20px 0;
}

.share-pdf-hr {
    margin-left: -100%;
    width: 300%;
    height: 1px;
    border: none;
    background: rgba(255, 255, 255, 0.3);
    //border-top: 1px solid red;
}

.share-pdf-spiner {
    pointer-events: none;
}

.share-pdf-button {
    display: flex;
    flex-direction: column;
    align-items: center;

    span {
        font-weight: 300;
        font-size: 11px;
    }
}