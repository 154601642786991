@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@300&display=swap');

/* Position and sizing of burger button */
.bm-burger-button {
  position: fixed;
  width: 30px;
  left: 30px;
  top: 40px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #fff;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: #b8b7ad;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  margin-top: 20px;
  margin-right: 15px;

  button {
    height: 40px !important;
  }
}

/* Color/shape of close button cross */
.bm-cross {
  background: #fff;
  height: 30px !important;
}

/*
  Sidebar wrapper styles
  Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
  */

@media only screen and (max-width: 768px) {
  .bm-menu-wrap {
    width: 100% !important;
  }
}

@media only screen and (max-height: 640px) {
  .bm-menu-profile {
    position: static !important;
  }
  .bm-menu-projects {
    position: static !important;
    border: none !important;
  }

  .bm-menu-logout {
    position: static !important;
  }
}

.bm-menu-wrap {
  position: fixed;
  height: 100%;
  top: 0;
  z-index: 99;
  border-right: 1px solid #272727;
  
  ul {
    list-style: none;
    padding-left: 0px;
    overflow-y: auto;
    overflow-x: hidden;

    li {
      text-align: left;
      color: #fff;
      font-family: 'Josefin Sans', sans-serif;
      font-size: 16px;
      letter-spacing: 1.92px;
      text-transform: uppercase;
      opacity: 1;
      padding: 5px 0;
      display: flex;
      align-items: center;
      width: 300px;

      &:first-child {
        margin-top: 0;
      }

      i {
        margin-left: 30px;
        background-repeat: no-repeat;
        background-position: center;
        width: 30px;
        height: 40px;
        margin-right: 10px;
      }
    }

    .item-menu {
      color: white;
      font-style: normal;

      &:hover {
        color: white;
        text-decoration: none;
      }
    }
  }
}

.bm-menu-logout {
  position: fixed;
  bottom: 10px;
  margin-bottom: 0;
}

.bm-menu-projects {
  border-top: 1px solid rgba(112, 112, 112, 0.3);
  position: fixed;
  bottom: 110px;
  width: 100%;
  padding-top: 15px;
}

.bm-menu-profile {
  position: fixed;
  bottom: 60px;
  margin-bottom: 0;
}

/* General sidebar styles */
.bm-menu {
  background: black;
  padding: 70px 0px 0 0;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  text-align: left;
  color: #fff;
  padding-top: 30px;
  font: normal normal 300 16px/19px Josefin Sans;
  letter-spacing: 1.92px;
  text-transform: uppercase;
  opacity: 1;
  background: black;
}

.lbl-menu {
  float: left;
  margin-top: -60px;
  z-index: 99;
  letter-spacing: 1.44px;
  font-size: 12px;
  padding-left: 30px;
}

.title-item-menu {
  position: relative;
  padding-top: 15px;
  padding-left: 15px;
}

/* Individual item */
.bm-item {}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}

@media (max-height: 50px) {
  .item-menu {
    font-size: 5px;
  }
}