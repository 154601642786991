@import url("https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@300&display=swap");


.pg-where-find {
  display: flex;
  flex-direction: column;
  width: 80%;
  text-align: left;
  background: #000;
  color: #fff;
  margin: 0 auto;
  font-family: Josefin Sans;

  animation: Animation 500ms;

  .content-description {
    font: normal normal 300 16px/22px Josefin Sans;
  }

  .content-header {
    display: flex;
    flex-direction: column;
    vertical-align: middle;
    width: 100%;
    margin-top: 20px;
    padding: 5px;
  }

  .content-title-first {
    color: #ffffff;
    font: normal normal 300 17px/21px Josefin Sans;
    letter-spacing: 2.52px;
    text-transform: uppercase;
    margin-bottom: .5rem;
  }

  .content-title-last {
    margin-top: -20px;
    margin-bottom: .5rem;
    color: white;
    font: normal normal normal 58px/62px BrisaRegular;
  }
}

.pg-where-findo-description {
  font: normal normal 300 16px/22px Josefin Sans;
  margin-bottom: 20px;
}

.pg-where-find-no-results {
  padding: 0 0 5px 15px;
  margin-bottom: .5rem;
}

.pg-where-find-div-list {
  flex-direction: column;
  margin-top: 20px;
  width: 100%;
  height: auto;
  color: #FFFFFF;

  h4 {
    font: normal normal 600 16px/22px Josefin Sans;
    letter-spacing: 1.92px;
    text-transform: uppercase;
  }

  div {
    margin-top: 20px;
    line-height: 1.4;

    span {
      display: inline-block;
      width: 100%;
      margin-bottom: 0px;
      font: normal normal 300 17px/17px Josefin Sans;
    }

    a {
      width: 100%;
      margin-bottom: 0px;
      font: normal normal 300 17px/17px Josefin Sans;
    }
  }
}