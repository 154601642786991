@font-face {
  font-family: "BrisaRegular";
  src: local("BrisaRegular"), url(./fonts/BrisaRegular.ttf) format("truetype");
}

@import url("https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@200&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@600&display=swap");

@keyframes AnimacaoPadrao {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes FadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes TransitionFadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translateY(200px);
    transform: translateY(200px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@keyframes TransitionFadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-200px);
    transform: translateY(-200px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@keyframes TransitionFadeInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translateX(200px);
    transform: translateX(200px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@keyframes TransitionFadeInRight {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-200px);
    transform: translateX(-200px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}

@keyframes TransitionFadeOutDown {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  100% {
    opacity: 0;
    -webkit-transform: translateY(300px);
    transform: translateY(300px);
  }
}

.FadeIn {
  -webkit-animation: FadeIn 1s ease;
  animation: FadeIn 1s ease;
}

* {
  outline: none !important;
}

html,
body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  color: #fff !important;
  background: #000 !important;
}

a,
a:hover,
button,
button:hover {
  text-decoration: none !important;
}

#outer-container {
  background-color: black;
  overflow: hidden;
}

#page-wrap {
  margin-top: 100px;
  // overflow: auto;
}

.header {
  height: 100px;
  width: 100%;
  background: black;
  position: fixed;
  top: 0;
  z-index: 99;
  border-bottom: 1px solid #272727 !important;
}

.titulo {
  color: #fff;
}

.div-titulo {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  text-align: center;
  height: 100px;
  margin: 0 auto;
  font: normal normal 600 20px/22px Josefin Sans;
}

.img-search {
  position: absolute;
  right: 40px;
  top: 40px;
  width: 25px;
}

.general-input {
  height: 54px;
  background: #000000;
  border-radius: 50px;
  border: 1px solid #707070;
  padding: 15px;
  text-align: left;
  width: 100%;
  color: #ffffff;
  font: normal normal 300 16px/22px Josefin Sans;
  outline: none;
}

form {
  max-width: 500px;
  margin: 0 auto;
}

input {
  &:hover, 
  &:focus {
    transition: none !important;
    -webkit-transition: none !important;
  }
  &:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 30px #030303 inset !important;
    box-shadow: 0 0 0 30px #030303 inset !important;
  }

  &:-webkit-autofill {
    -webkit-text-fill-color: #FFFFFF !important;
  }
}

.general-btn {
  border-radius: 50px;
  padding: 18px;
  font: normal normal bold 18px/22px Josefin Sans;
  letter-spacing: 1.92px;
  color: #030303;
  background: #ffffff;
  border: none;
  width: 100%;
  height: 52px;
  transition: all .2s linear;

  &:hover {
    background: #fff;
    color: #030303;
    text-decoration: none;
  }

  &:disabled {
    background: #333333;
    color: #707070;
    cursor: not-allowed;
    transition: all .2s linear;
  }
}

.general-lbl-above-inputs {
  margin-top: 10px;
  margin-bottom: 5px;
  width: 100%;
  font: normal normal 400 16px/22px Josefin Sans;
}

.general-lbl-info-message {
  width: 100%;
  font: normal normal 300 12px/20px Josefin Sans !important;
  text-align: center;
  margin-top: 2px;
  margin-bottom: 0px;
}

.modal-header,
.modal-footer {
  padding: .85rem!important;
}

.modal-header .close {
  color: #ffffff;
  font-size: 3rem;
  font-weight: 300;
  padding: 0!important;
  margin-right: 0!important;
  line-height: 0.5;
  opacity: 1;

  span {
    text-shadow: none;
  }

  &:hover {
    color: #ffffff;
  }
}