@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@300&display=swap');

@keyframes animacao {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes AnimatedBackground {
  from {
    background-position: center 689px;
  }

  to {
    background-position: center 0;
  }
}

.AnimatedBackground {
  -webkit-animation: AnimatedBackground 3s ease;
  animation: AnimatedBackground 3s ease;
}

.TransitionFadeInUp {
  -webkit-animation: TransitionFadeInUp 3s ease;
  animation: TransitionFadeInUp 3s ease;
}

.TransitionFadeInRight {
  -webkit-animation: TransitionFadeInRight 3s ease;
  animation: TransitionFadeInRight 3s ease;
}

.TransitionFadeInLeft {
  -webkit-animation: TransitionFadeInLeft 3s ease;
  animation: TransitionFadeInLeft 3s ease;
}

.scroll-down {
  cursor: pointer;
}

.pg-home-lbl-title {
  font: normal normal 300 18px/22px Josefin Sans;
  letter-spacing: 2.16px;
  color: #FFFFFF;
  text-transform: uppercase;
  opacity: 1;
  margin-top: 35vh;
  margin-bottom: .5rem;
}

.pg-home-lbl-title-highlight {
  font: normal normal bold 45px/54px Josefin Sans;
  letter-spacing: 5.4px;
  color: #FFFFFF;
  text-transform: uppercase;
  opacity: 1;
  margin-bottom: .5rem;
}

.pg-home-lbl-title-hashtag {
  font: normal normal 300 18px/22px Josefin Sans;
  letter-spacing: 2.16px;
  color: #FFFFFF;
  text-transform: uppercase;
  opacity: 1;
  margin-bottom: .5rem;
}

.pg-home-div-content {
  position: relative;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  width: 100vw;
  height: 90vh;
  margin: 0 auto;

  img {
    display: block;
    position: relative;
    max-width: 100%;
    width: 50%;
  }
}

.pg-home-div-overlay {
  width: 100vw;
  height: 100vh;
  background: #000;
}

.pg-home-div-footer {
  position: absolute;
  height: 60px;
  width: 100%;
  bottom: 20px;

  a {
    color: #fff;
  }
}