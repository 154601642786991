.pg-filter-div-content {
  @import url("https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@300&display=swap");
  @import url("https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@600&display=swap");

  display: flex;
  flex-direction: column;
  width: 80%;
  height: auto;
  max-width: 500px;
  text-align: left;
  background: #000;
  color: #fff;
  margin: 0 auto;

  .selected-category {
    text-transform: uppercase;
    font-weight: 300;
    margin-bottom: .5rem;

    svg {
      margin-top: -3px;
      margin-left: 8px;
      color: #707070;
      border: 1px solid #707070;
      border-radius: 25px;
    }
  }

  .container-radio-button {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
  }

  .content-radio-button {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 8px;
    overflow-wrap: break-word;
    word-wrap: break-word;
    hyphens: auto;
  }

  .content-radio-button>label {
    margin-top: 15px;
    margin-bottom: .5rem;
    margin-left: 15px;
    margin-right: 15px;
  }

  label {
    font: normal normal 200 16px/22px Josefin Sans;
    margin-top: 10px;
  }

  span {
    font: normal normal 400 16px/22px Josefin Sans;
    margin-top: 10px;
  }

  button {
    border-radius: 50px;
    padding: 18px;
    margin-top: 40px;
    font: normal normal 600 16px/22px Josefin Sans;
    letter-spacing: 1.92px;
    color: #030303;
    background: #fff;

    &:hover {
      text-decoration: none;
      color: #000;
      background: #fff;
    }
  }
}

.search-btn {
  border-radius: 52px;
  padding: 18px;
  font: normal normal bold 18px/22px Josefin Sans;
  letter-spacing: 1.92px;
  color: #030303;
  background: #fff;
  border: none;
  width: 45%;
  height: 52px;

  &:hover {
    background: #fff;
    color: #030303;
    text-decoration: none;
  }
}

.search-div-content {
  height: 100px;
  width: 100%;
  background: black;
  position: fixed;
  top: 0;
  z-index: 99;
  border-bottom: 1px solid #272727 !important;
}

.search-div-titulo {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  text-align: center;
  height: 100px;
  margin: 0 auto;
  font: normal normal 600 20px/22px Josefin Sans;
}

.search-img-search {
  vertical-align: middle;
  cursor: pointer;
}

.label-properties {
  color: white !important;
}

.search-div-search {
  position: relative;
  background: #000000;
  width: 100%;
  max-height: 160px;
  height: 160px;
  padding: 20px 8%;
  margin-top: -20px;
  border-bottom: 1px solid #707070;
  transition: height 2s;
  z-index: 10;
}

.search-input-search {
  width: 90%;
  height: 40px;
  border: none;
  font: normal normal 300 16px/22px Josefin Sans;
  border-radius: 35px;
  color: #ffffff;
  background: #000000;
}

.search-box-search {
  border-radius: 25px;
  border: 1px solid #707070;
  padding: 6px 6px 6px 8px;
  min-height: 52px;
  background: #000000;
}

.search-lbl-search-title {
  font: normal normal 300 16px/22px Josefin Sans;
  color: #ffffff;
  margin-bottom: .5rem;
}

.search-lbl-search-examples {
  margin-top: 10px;
  margin-bottom: .5rem;
  font: normal normal 300 12px/22px Josefin Sans;
  color: #707070;
}

.search-list {
  width: 90%;
  background: #000000;
  //padding-top: 20px;
  list-style: none;
  border-top: none;
  height: auto;
  max-height: 25vh;
  overflow-x: auto;
  margin-left: 6px;

  ul {
    margin-left: -40px;

    li {
      text-align: left;
      color: #ffffff;
      font: normal normal 400 16px/22px Josefin Sans;
      text-transform: capitalize;
      opacity: 1;
      padding: 5px 0;
      display: flex;

      &:first-child {
        margin-top: 20px;
      }

      &:last-child {
        margin-bottom: 10px;
      }
    }
  }
}

input[type="radio"]:after {
  width: 20px;
  height: 20px;
  border-radius: 15px;
  top: -2px;
  left: -1px;
  position: relative;
  background-color: black;
  content: "";
  display: inline-block;
  visibility: visible;
  border: 1px solid #707070;
}

input[type="radio"]:checked:after {
  width: 20px;
  height: 20px;
  border-radius: 15px;
  top: -2px;
  left: -1px;
  position: relative;
  background-color: white;
  content: "";
  display: inline-block;
  visibility: visible;
  border: 1px solid #707070;
}

.pg-filter-div-buttons {
  display: flex;
  justify-content: space-between;
  width: 100%;
  text-align: center;
  color: #fff;
  margin: 0 auto;

  .pg-filter-span-clear {
    color: white;
    background: #000000;
    font: normal normal 300 16px Josefin Sans;
    letter-spacing: 2.4px;
    text-transform: uppercase;
    opacity: 1;
    cursor: pointer;

    padding: 18px;
    color: #ffffff;
    background: #000000;
    border: none;
    height: 52px;
    width: 45%;

    &:hover {
      color: #ffffff;
      background: #000000;
      text-decoration: none;
    }
  }

}