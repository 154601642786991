@import url("https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@300&display=swap");


.pg-about-div-content {
  display: flex;
  flex-direction: column;
  width: 80%;
  text-align: left;
  background: #000;
  color: #fff;
  margin: 0 auto;
  font-family: Josefin Sans;

  animation: Animation 500ms;
}

.pg-about-description {
  font: normal normal 300 16px/22px Josefin Sans;
  text-align: justify;
}

.pg-about-header {
  display: flex;
  flex-direction: column;
  vertical-align: middle;
  width: 100%;
  margin-top: 20px;
  padding: 5px;
}

.pg-about-lbl-about {
  margin-bottom: .5rem;
  color: #ffffff;
  font: normal normal normal 88px/92px BrisaRegular;
  display: inline-block;
}