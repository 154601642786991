.SearchFadeInDown {
  -webkit-animation: TransitionFadeInDown 1s ease;
  animation: TransitionFadeInDown 1s ease;
}

.header-div-content {
  height: 100px;
  width: 100%;
  background: black;
  position: fixed;
  top: 0;
  z-index: 99;
  border-bottom: 1px solid #272727 !important;
}

.header-div-titulo {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 99;
  text-align: center;
  height: 100px;
  margin: 0 auto;
  font: normal normal 600 20px/22px Josefin Sans;
}

.header-img-search {
  position: absolute;
  right: 40px;
  top: 38px;
  vertical-align: middle;
  cursor: pointer;
}

.header-div-search {
  position: relative;
  background: #000000;
  width: 100%;
  max-height: 160px;
  height: 160px;
  padding: 20px 8%;
  margin-top: -20px;
  border-bottom: 1px solid #707070;
  transition: height 2s;
}

.header-input-search {
  width: 100%;
  padding-right: 40px;
  padding-left: 10px;
  height: 40px;
  border: none;
  font: normal normal 300 16px/22px Josefin Sans;
  border-radius: 15px;
  color: #ffffff;
  background: #000000;
}

.header-input-search-btn {
  position: absolute;
  top: 15px;
  right: 15px;
  cursor: pointer;
}

.header-box-search {
  position: relative;
  border-radius: 25px;
  border: 1px solid #707070;
  padding: 6px 6px 6px 8px;
  min-height: 52px;
  background: #000000;
}

.header-lbl-search-title {
  display: inline-block;
  margin-bottom: .5rem;
  text-align: left;
  font: normal normal 300 16px/22px Josefin Sans;
  color: #ffffff;
}

.header-lbl-search-examples {
  display: inline-block;
  margin-top: 10px;
  margin-bottom: .5rem;
  font: normal normal 300 12px/22px Josefin Sans;
  color: #707070;
}

.header-list {
  background: #000000;
  list-style: none;
  border-top: none;
  height: auto;
  max-height: 25vh;
  overflow-x: auto;
  margin-left: 10px;
  margin-right: 10px;

  ul {
    margin-left: -40px;

    li {
      text-align: left;
      color: #ffffff;
      font: normal normal 400 16px/22px Josefin Sans;
      text-transform: capitalize;
      opacity: 1;
      padding: 5px 0;
      display: flex;

      &:first-child {
        margin-top: 20px;
      }

      &:last-child {
        margin-bottom: 10px;
      }
    }
  }
}