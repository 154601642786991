.search-map {

  position: relative;

  input {
    background: #000000;
    color: #ffffff;
    padding: 10px 35px 10px 15px;
    margin-bottom: 15px;
    width: 100%;
    border: 1px solid #707070;
    border-radius: 25px;
  }

  .search-map-close {
    position: absolute;
    top: 13px;
    right: 13px;
    cursor: pointer;
  }

  .search-map-results {
    background: #000000;
    color: #ffffff;
    border: 1px solid #707070;
    border-radius: 25px;
    padding: 10px 15px;
    overflow-y: auto;
    width: 100%;
    max-height: 30vh;
    position: absolute;
    z-index: 1;
  }

  .search-map-loading {
    text-transform: uppercase;
    letter-spacing: 2.42;

    hr {
      color: #707070;
    }
  }
}